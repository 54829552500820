import React, { useState } from 'react';
import LandingBanner from './LandingBanner';
import BoothInfo from './BoothInfo';
import LandingTestimonials from './LandingTestimonials';
import LandingBoothSection from './LandingBoothSection';
import LandingImageBanner from './LandingImageBanner';
import LandingLayout from './Layout/LandingLayout';
import ContactUsModal from './ContactUsModal';
import "../../../assets/css/landingstyles.css";

function Home() {
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const toggleModal = () => {
        setShow(true)
    }
    return (
        <div className="landing-cover">
            <LandingLayout toggleModal={toggleModal}>
                <LandingBanner />
                <BoothInfo />
                <LandingImageBanner />
                <LandingBoothSection toggleModal={toggleModal} />
                <LandingTestimonials />
                <ContactUsModal show={show} onHide={handleClose} />
            </LandingLayout>
        </div>
    )
}

export default Home