import React from 'react';
import Modal from 'react-bootstrap/Modal'
import ContactForm from "./ContactForm"

const ContactUsModal = (props) => {
    return (
        <Modal
            centered
            size={"lg"}
            {
            ...props
            }>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="landing-cover-modal">
                    <ContactForm {...props} />
                </div>
            </Modal.Body>
            {/* <Modal.Footer>

            </Modal.Footer> */}
        </Modal>
    )
}

export default ContactUsModal