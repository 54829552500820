import React from 'react'
import speakerOutline from '../../../assets/images/landingImages/speaker-outline.png'
import speaker from '../../../assets/images/landingImages/speaker.png'
import speakerSmall from '../../../assets/images/landingImages/speaker-small.png'
import { handleBooking } from './functions'

const LandingBoothSection = (props) => {
    const { toggleModal } = props
    return (
        <>
            <div className="product-sec section-gap">
                <div className="container">
                    <div className="product-wrapper">
                        <div className="product-left">
                            <img src={speakerOutline} />
                        </div>
                        <div className="product-center">
                            <img src={speaker} />
                            <div className="sale" onClick={handleBooking}>
                                <p>Reserve a Booth </p>
                            </div>
                        </div>
                        <div className="product-right">
                            <img src={speakerSmall} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="question section-gap">
                <div className="container">
                    <div className="row">
                        <div className="question-left">
                            <h2>Have a question, or didn't find what you were looking for?</h2>
                        </div>
                        <div className="question-right">
                            <div className="banner-btn-wrapper">
                                <a href={false} onClick={toggleModal} className="btn btn-blue">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingBoothSection
