import React from 'react'
import animated1 from '../../../assets/images/gifimg1.gif'
import animated2 from '../../../assets/images/gifimg2.gif'
import animated3 from '../../../assets/images/photo_banner/sydneygif.gif'

const LandingImageBanner = () => {
    return (
        <div className="animated section-gap">
            <div className="container">
                <div className="animated-box">
                    <div className="animated-box-image">
                        <img src={animated1} alt="Image" />
                    </div>
                    <div className="animated-box-content">
                        <h2 className="title">Bring the fun to the guests</h2>
                        <p className="text-content">Share anywhere videos are supported be honest,is pretty much the entire internet.</p>
                    </div>
                </div>
                <div className="animated-box reverse">
                    <div className="animated-box-image">
                        <img src={animated2} alt="Image" />
                    </div>
                    <div className="animated-box-content">
                        <h2 className="title">Your brand is in the spotlight</h2>
                        <p className="text-content">Visual experiences for the brands, agencies and event planners. Brand our event products or have us create something custom for your activation.</p>
                    </div>
                </div>
                <div className="animated-box">
                    <div className="animated-box-image">
                        <img src={animated3} alt="Image" />
                    </div>
                    <div className="animated-box-content">
                        <h2 className="title">Booth Branding</h2>
                        <p className="text-content">Our photo booths allow us to create incredible custom photo experiences and corporate activations that drive consumer engagement and elevate brand exposure.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingImageBanner
