import React from 'react'

import wedding from '../../../assets/images/landingImages/wedding.png'
import event from '../../../assets/images/landingImages/event.png'
import corporate from '../../../assets/images/landingImages/corporate.png'
import parties from '../../../assets/images/landingImages/parties.png'
import { navigate } from 'gatsby'

const BoothInfo = () => {



    return (
        <div className="what-is-it section-gap">
            <div className="container">
                <h1 className="title">What is it?</h1>
                <p className="text-content">Phramebooth is a new style open air photo booth with modern design cutting-edge technology.
                   
                </p>
                <p className="text-content tcontent-bottom"> Your guest will love sharing awesome pictures to their phone, social media or email, as well as being able to print
                    them as a keepsake. It is the perfect for all kinds of parties and events.</p>
                <div className="what-box-wrapper">
                    <EventCard title="Wedding Parties" img={wedding} classS="" />
                    <EventCard title="Special Events" img={event} classS="b2" />
                    <EventCard title="Corporate Events" img={corporate} classS="b3" />
                    <EventCard title="Parties" img={parties} classS="b4" />
                </div>
            </div>
        </div>
    )
}

export default BoothInfo

const EventCard = (props) => {

    const { title, img, classS } = props;

    const handleClick = () => {
        navigate('/events')
    }

    return (
        <div className="what-box-inner">
        <div className={`what-box ${classS}`} onClick={handleClick}>
            <div className='box-image'>
                <img src={img} alt="Image" />
            </div>
            <h3 className="box-title">{title}</h3>
        </div>
        <div className="outline-wrap"></div>
        </div>
    )
}
