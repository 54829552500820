import React from 'react'
import star from '../../../assets/images/landingImages/star-rating.png'

const LandingTestimonials = () => {
    return (
        <div className="testimonial section-gap">
            <div className="container">
                <h2 className="title">What the people say</h2>
                <div className="testimonial-box-wrapper">
                    <div className="testimonial-box">
                        <span>
                            <img src={star} />
                        </span>
                        <h3 className="testimonial-title">"LOTS OF FUN PICTURES"</h3>
                        <p>Our guests had so much fun! People were taking photos all night, and now we have lots of fun pictures to put in the album we used for a guest book.</p>
                    </div>
                    <div className="testimonial-box">
                        <span>
                            <img src={star} />
                        </span>
                        <h3 className="testimonial-title">"THE LATEST TREND"</h3>
                        <p> My guests had a ✨blast✨ with this at my wedding at the Minnesota History Center! It was super cool to have the latest trend of a photo booth at my wedding! Guests loved being able to watch other guests use the photo booth, and it was nice because you could fit a LOT of people in a picture if you wanted since it's all open.
                            🔥 🔥 🔥 🔥✨✨</p>
                    </div>
                    <div className="testimonial-box">
                        <span>
                            <img src={star} />
                        </span>
                        <h3 className="testimonial-title">"Worth Every Penny"</h3>
                        <p>The photo booth was the hit of the wedding wouldn’t have been the same without it. Thanks again “ It was worth every penny.</p>
                    </div>
                    <div className="testimonial-box">
                        <span>
                            <img src={star} />
                        </span>
                        <h3 className="testimonial-title">"LOTS OF FUN PICTURES"</h3>
                        <p> Our guests had so much fun! People were taking photos all night, and now we have lots of fun pictures to put in the album we used for a guest book.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingTestimonials
