import React from 'react'
import center from '../../../assets/images/photo_banner/IMG_3461.jpeg'
import right from '../../../assets/images/photo_banner/IMG_3410.gif'
import { navigate } from 'gatsby'

const LandingBanner = () => {
    return (
        <div className="banner">
        <div className="container">
          <div className="row">
            <div className="banner-left">
              <h2 className="banner-title">
                Rent the Ultimate Phramebooth Experience 
              </h2>
              {/* <div className="banner-btn-wrapper">
                <a href={false} onClick={() => navigate('/reserve-booth')} className="btn btn-pink">Reserve a Booth</a> 
              </div> */}
            </div>
            <div className="banner-right">
              <div className="image-left">
              </div>
              <div className="image-center">
                <img src={center} alt="Banner Image" />
              </div>
              <div className="image-right">
                <img src={right} alt="Banner Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default LandingBanner
