import { toaster } from 'evergreen-ui'
import React, { useState } from 'react'
import "../../../assets/css/landingstyles.css"
import { withFirebase } from '../../../components/Firebase'
import { Alert } from "react-bootstrap"
const ContactForm = (props) => {
    const { onHide, firebase } = props
    const [mailForm, setMailForm] = useState({
        name: "",
        email: "",
        message: "",
        phone: "",
    })
    const [hasError, setHasError] = useState(false)
    const handleChange = (e) => {
        setMailForm({ ...mailForm, [e.target.name]: e.target.value })
    }
    const onMailSend = async () => {
        // firebase.functions.useFunctionsEmulator('http://localhost:5001')
        const contactUsMail = firebase.functions.httpsCallable('contactUsMail');
        try {
            await contactUsMail(mailForm)
                .then(async (result) => {
                    console.log(result)
                    if (result.data?.success) {
                        onHide()
                        toaster.success("Mail has been successfully sent.")
                        setHasError(false)
                    } else {
                        setHasError(true)
                    }
                }).catch((error) => {
                    setHasError(true)
                    console.log(error)
                })
        } catch (error) {
            console.log(error);
            setHasError(true)
        }
    }
    return (
        <div className="contact section-gap" id="contactus">
            <div className="container">
                {/* <h2 className="title">We'd love to hear from you!</h2> */}
                {hasError ?
                    <Alert key={1} variant="danger">
                        Something went wrong please try again.
                    </Alert>
                    :
                    <p className="content-text">Please feel free to leave us a message, or email us at <a href="mailto:info@phramebooth.com">info@phramebooth.com</a>, and we'll get back to you as soon as possible.</p>
                }
                <form method="post">
                    <div className="contact-form">
                        <div className="form-row">
                            <input type="text" name="name" onChange={handleChange} placeholder="Your Name" className="form-control" />
                            <input type="email" name="email" onChange={handleChange} placeholder="Your Email" className="form-control" />
                        </div>
                        <div className="form-row">
                            <input type="text" name="phone" onChange={handleChange} placeholder="Your Phone (optional)" className="form-control" />
                        </div>
                        <div className="form-row">
                            <textarea className="form-control" name="message" onChange={handleChange} placeholder="Your Message" defaultValue={""} />
                        </div>
                        <div className="btn-wrap">
                            <button className="sbtn btn-close" type="button" onClick={onHide}>Close</button>
                            <input type="button" className="sbtn" defaultValue="Send Message" onClick={onMailSend} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default withFirebase(ContactForm)
