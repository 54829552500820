import React from 'react'

import SEO from '../components/seo'
// import { Home } from '../Views'
import Home from '../Views/Home/LandigPage/Home'

const IndexPage = () => {

	return (
		<>
			<SEO title="Home" />
			<Home/>
		</>
	)
}

export default IndexPage